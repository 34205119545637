import axios from 'axios';
import { auth } from '../auth/auth';
import { appDebug } from './utils';
import { CustomWindowForAuth } from '../auth/auth';

declare let window: CustomWindowForAuth;

export const https = axios.create({
    baseURL: window.DAETAMA_UI_VARS.apiUrl ?? 'MISSING URL CHECK CONFIG',
    timeout: 10_000,
    headers: {
        Authorization: 'Bearer AWAITING_TOKEN_ONCE_LOGGED_IN',
    },
});

auth.onAuthStateChanged(() => {
    https.interceptors.request.use(async (config) => {
        if (auth.currentUser) {
            const token = await auth.currentUser.getIdToken();
            config.headers!.Authorization = `Bearer ${token}`;
        }
        return config;
    }, appDebug);
});

https.interceptors.request.use(async (config) => {
    if (auth.currentUser) {
        const token = await auth.currentUser.getIdToken();
        config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
}, appDebug);
